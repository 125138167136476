@import './fonts.scss';

html,
body {
  height: 100%;

  #root {
    height: 100%;
  }
}
